// import firebase from "firebase/app"
// import "firebase/auth"
import apiHandler from "@/services/Api.js";
import API from "@/constants/api-endpoints.js";
import AuthToken from "@/services/AuthToken";
import store from "@/store";
import router from "@/router";
export default {
  state: {
    loggedInUser:
      localStorage.getItem("authToken") != null
        ? localStorage.getItem("authToken")
        : null,
    // loggedInUser:    {
    //       mobile_no: "9999999999",
    //       user_name: "Kartik"
    //     },
    responseData:null,
    loading: false,
    error: null
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    error: state => state.error,
    responseData:state => state.responseData
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data;
      state.loading = false;
      state.error = null;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.questionVideoArr = [];
      state.currentQuestion = null;
      state.questionDetail = {};
      state.questionSetWithChild = [];
      state.interviewQuestionCount = 0;
      state.allFeedbackFormTemplates = [];
      state.loading = false;
      state.error = null;
      localStorage.removeItem("vuex");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userInfo");
    },
    setLoading(state, data) {
      state.loading = data;
      state.error = null;
    },
    setResponseData(state, data) {
      state.responseData = data;
      state.error = null;
    },
    setError(state, data) {
      state.error = data;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    }
  },
  actions: {
    async login({ commit }, data) {
      commit("setLoading", true);
      commit("setResponseData",null);

      commit("clearError");
      await apiHandler
        .post(API.AUTH_ENDPOINTS.LOGIN, data)
        .then(res => {
          if (res.data.status == true) {
            this.isLoading = false;
            const userData = res.data.data.candidate;
            userData.is_verified = false;
            commit("setUser", userData);
            return userData;
          } else if(res.data.status == false && res.data.data){
            commit("setResponseData", res.data);

          }else {
            commit("setError", res.data);
          }
        })
        .catch(err => {
          commit("setError", err);
        });
    },
    async verifyOtp({ commit }, data) {
      commit("setLoading", true);
      commit("clearError");
      // const formData = { user_emailaddress: data.user_emailaddress, user_password: data.user_password, user_mpin : data.user_mpin }
      // const formData = { mobile_no: data.mobile_no }
      // AuthToken.saveToken('userData.user_token')
      // commit("setUser", {mobile_no: data.mobile_no, user_name:'Kartik'})
      // return data
      await apiHandler
        .post(API.AUTH_ENDPOINTS.verify_otp, data)
        .then(res => {
          if (res.data.status == true) {
            const userData = res.data.data.candidate;
            userData.user_name = userData.name.split(" ")[0];
            userData.is_verified = true;
            commit("setUser", userData);
            return userData;
          } else {
            commit("setError", res.data);
          }
        })
        .catch(err => {
          commit("setError", err);
        });
    },
    async getClientDetail({ commit }, data) {
      commit("setLoading", true);
      commit("clearError");
      console.log('getClientDetail')
      // const formData = { user_emailaddress: data.user_emailaddress, user_password: data.user_password, user_mpin : data.user_mpin }
      const formData = { user_key: data.user_key, assign_template_key: data.assign_template_key }
      // AuthToken.saveToken('userData.user_token')
      // commit("setUser", {mobile_no: data.mobile_no, user_name:'Kartik'})
      // return data
      await apiHandler
        .post(API.AUTH_ENDPOINTS.Get_Clinet_Detail, formData)
        .then(res => {
          // console.log(res.data)
          // console.log(res.data.success)
          if (res.data.success == true) {
            const userData = res.data.data;
            commit("setUser", userData);
            return userData;
          } else {
            commit("setError", res.data);
          }
        })
        .catch(err => {
          commit("setError", err);
        });
    },
    signOut({ commit }) {
      console.log('signOut')
      localStorage.removeItem("vuex");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userInfo");
      commit("setLogout");
    }
  }
};
