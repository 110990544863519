<template>
 <div class="modal" id="exampleModalCenter" tabindex="-1"   :style="{'display': installBtn}">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Introducing our Web App
            </h5>
          </div>
          <div class="modal-body">
            <h5>Steps:</h5>
            <ul class="list-unstyled">
              <li>1) Install Application</li>
              <li>2) Add feedback and submit.</li>
            </ul>
          </div>
          <div class="modal-footer">
            <a
              href="javascript:void(0)"
              class="btn btn-form"
                @click="installer()"
              >Install</a
            >
          </div>
        </div>
      </div>
    </div>
  <!-- <vodal
    :show="deferredPrompt"
    animation="fade"
    :width="width"
    :close-button="false"
  >
    <div class="update-vodal">
      <div class="logo">
        <img
          alt="no-image"
          src="@/assets/cmart-logo.svg"
          class="img-fluid"
        >
      </div>
      <div class="update-vodal__content">
        <h2>Introducing our Web App</h2>
        <h4>Add Cmart to your homescreen to get a better shopping experience.</h4>

        <div class="text-center">
          <a
            href="javascript:void(0)"
            class="btn btn-primary btn-form"
            @click="install"
          >Install</a>
        </div>
      </div>
    </div>
  </vodal> -->
</template>
<script>

export default {
  name: 'InstallComponent',
  data () {
    return {
      deferredPrompt: false,
      width: 275,
         installBtn: "none",
      installer: undefined
    }
  },
    created() {
    let installPrompt;

    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      installPrompt = e;
      this.installBtn = "block";
    });

    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoice.then(result => {
        if (result.outcome === "accepted") {
          console.log("Install accepted!")
        } else {
          console.log("Install denied!")
        }
      });
    };
  },
  // created () {
  //   window.addEventListener('beforeinstallprompt', e => {
  //     e.preventDefault()
  //     this.deferredPrompt = e
  //   })
  //   window.addEventListener('appinstalled', () => {
  //     this.deferredPrompt = false
  //   })
  // },
  methods: {
    async install () {
      this.deferredPrompt.prompt()
    }
  }
}
</script>
