<template>
  <div class="home main">
    <!-- <img alt="Vue logo" src="@/assets/logo.png"> -->
    <!-- splash screen -->
    <!-- <div class="wrapper"> -->
    <div class="main-design">
      <div class="topImg">
        <!-- <img src="../assets/images/top.png" alt="top" class="img-fluid topDesign"> -->
      </div>
      <div class="container">
        <div class="content">
          <div class="logoNeosoft">
            <!-- <img src="../assets/images/neosoft.svg" alt="neosoft" class="img-fluid"> -->
          </div>
          <div class="dream-text invalid">
            <img
              src="../assets/images/sorry-img.svg"
              alt="sorry"
              class="img-fluid"
            />
            <h1>Sorry</h1>
            <p>
              Your feedback link seems to not be working.Please contact the HR
            </p>
            <!-- <router-link to="/login"><button class="getBtn">Let's get started</button></router-link> -->
          </div>
        </div>
      </div>
      <!-- <div class="bottomImg invalid-bottom">
    </div> -->
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home"
};
</script>
