<template>
 <!-- <div id="app" class="pt-sans" style="background: url(https://www.geeklawblog.com/wp-content/uploads/sites/528/2018/12/liprofile-656x369.png;color:#fff;"> -->
  <div id="app"  :class="[(themeClass !='' ? themeClass :   'pt-sans'),( isIOSDevice == true ? iosClass :   '')]" :style="{ backgroundImage: `url(${selectedTheme})`}">
    <div class="modal" id="exampleModalCenter" tabindex="-1"   :style="{'display': updateExists ? 'block' :  'none'}">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              New Update Available
            </h5>
          </div>
          <div class="modal-body">
            <h5>What's New:</h5>
            <ul class="list-unstyled">
              <li>1) Bug fixes</li>
              <li>2) Optimised User Experience</li>
            </ul>
          </div>
          <div class="modal-footer">
            <a
              href="javascript:void(0)"
              class="btn btn-form"
              @click="refreshApp"
              >Update</a>
          </div>
        </div>
      </div>
    </div>
    <install-modal />
    <router-view />
  </div>
</template>

<script>
import update from "./mixins/update";
import InstallModal from '@/components/installmodal.vue'
import { mapGetters, mapActions, mapState } from "vuex";
import { imageBaseUrl } from '@/constants/api-endpoints.js'
// import Bugsnag from '@bugsnag/js'

export default {
  name: "App",
  mixins: [update],
  data() {
    return {
      isStandAloneMode: false,
      checkTheme:'',
      imageUrl: imageBaseUrl,
      selectedTheme:'',
      selectedColor:'#2c3e50',
      themeClass:'',
      isIOSDevice:false,
      iosClass:''
    };
  },
  components: {
    InstallModal
  },
  mounted() {
    // this.$store.commit("RESTORE_MUTATION");
    //Bugsnag.notify('testing')s
    this.checkTheme = this.templateTheme
    if (window.matchMedia("(display-mode: standalone)").matches) {
      this.isStandAloneMode = true;
      console.log("display-mode is standalone");
    }
    this.checkIOSDevice()
  },
   watch: {
     templateTheme: {
        immediate: true,
        handler(newVal, oldVal) {
          // console.log("newVal", newVal, ' oldVal ', oldVal)
          this.setThemeBackground(newVal)
        }
      },
    },
   computed: {
    ...mapGetters([
      "loggedInUser",
      "templateTheme"
    ])
   },
  methods: {
    loadtheme() {
      const theme = this.$store.state.themename;
      this.themename = theme;
    },
    setThemeBackground(newVal) {
      console.log('newVal  ', newVal)
      if(newVal != '' && newVal.theme_background_image && newVal.theme_background_image != '') {
        console.log('in,,,,,')
         this.selectedTheme = this.imageUrl + "uploads/template_theme/" + newVal.theme_background_image
        this.selectedColor = newVal.theme_font_color
        this.themeClass = newVal.theme_class

        this.$nextTick(() => {
          // console.log('nextTick')
          this.selectedTheme = this.imageUrl + "uploads/template_theme/" + newVal.theme_background_image
        });
      }
    },
    checkIOSDevice() {
      // console.log(this.$browserDetect.isChrome, ' isChrome' )
      // console.log(this.$browserDetect.isIE, ' isIE' )
      // console.log(this.$browserDetect.isFirefox, '  isFirefox' )
      // console.log(this.$browserDetect.isOpera , ' isOpera')
      // console.log(this.$browserDetect.isSafari , ' isSafari')
      // console.log(this.$browserDetect.isEdge , ' isEdge')
      // console.log(this.$browserDetect.isChromeIOS  , ' isChromeIOS ')
      // console.log(this.$browserDetect.isIOS  , ' isIOS ')
      // console.log(this.$browserDetect.ua   , ' ua  ')
      // console.log(this.$browserDetect.meta)
      const check =  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      if(check){
        this.isIOSDevice = true
        console.log("I am an IOS device!");
        if(this.$browserDetect.isChrome){
           this.iosClass  = 'ios-chrome'
        }else if(this.$browserDetect.isIE){
           this.iosClass  = 'is-ie'
        }else if(this.$browserDetect.isFirefox){
           this.iosClass  = 'ios-firefox'
        }else if(this.$browserDetect.isOpera){
           this.iosClass  = 'ios-opera'
        }else if(this.$browserDetect.isSafari){
           this.iosClass  = 'ios-safari'
        }else if(this.$browserDetect.isEdge){
           this.iosClass  = 'ios-edge'
        }else if(this.$browserDetect.isChromeIOS){
           this.iosClass  = 'ios-chrome'
        } else if(this.$browserDetect.isIOS){
           this.iosClass  = 'ios-safari'
        }else {
          this.iosClass  = 'ios-safari'
        }

      } else {
        this.isIOSDevice = false

      }
      console.log('this.isIOSDevic--------', this.isIOSDevice)
      console.log('this.iosClass--------', this.iosClass)
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  /* a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  } */
}
</style>
