import axios from 'axios'
import AuthToken from './AuthToken'
const API_URL = process.env.VUE_API_BASE_URL
import store from '@/store'
import router from '@/router'


const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
})

export default {
  getApiUrl() {
    return API_URL
  },
  get(url, passPayload = 0) {
    this.addHeaders()
    return axiosApi.get(url, {
      params: passPayload
    }).then(respose => {
      if(respose.data.data.authorizationFailed) {
        store.commit('setUser','')
        AuthToken.logoutUser()
        router.push('/signIn')
      }
      return respose
    }).catch(err =>{
      if(err.response.status === 401){
        store.commit('AUTH_USER','')
        AuthToken.logoutUser()
        router.push('/signIn')
      }
    })
  },
  post(url, payload, queryParam = '') {
    this.addHeaders()
    let qparam = ''
    if(queryParam) {
     qparam = {params:queryParam}
    }
    return axiosApi.post(url, payload, qparam).then(respose => {
      if(respose.data.data.authorizationFailed) {
        store.commit('setUser','')
        AuthToken.logoutUser()
        router.push('/signIn')
      }
      return respose
    }).catch(err =>{
      if(err.response.status === 401){
        store.commit('AUTH_USER','')
        AuthToken.logoutUser()
        router.push('/signIn')
      }else{
        return err.response
      }
    })
  },
  put(url, payload, passPayload = 0) {
    this.addHeaders()
    return axiosApi.put(url, payload).then(respose => {
      if(respose.data.data.authorizationFailed) {
         store.commit('setUser','')
        AuthToken.logoutUser()
        router.push('/signIn')
      }
      return respose
    }).catch(err =>{
      if(err.response.status === 401){
        store.commit('AUTH_USER','')
        AuthToken.logoutUser()
        router.push('/signIn')
      }else{
        return err.response
      }
    })
  },
  delete(url, payload) {
     this.addHeaders()
    return axiosApi.delete(url, {
      params: payload
    }
      ).then(respose => {
      return respose
    }).catch(err =>{
      if(err.response.status === 401){
        store.commit('AUTH_USER','')
        AuthToken.logoutUser()
        router.push('/signIn')
      }else{
        return err.response
      }
    })
  },
  upload(url, payload, passPayload = 0) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' }}
    return axiosApi.post(url,payload, config).then(respose => {
      return respose
    })
  },
  getpdf(url, payload, passPayload = 0) {
    const config = { headers: { 'responseType': 'blob' }}
    return axiosApi.post(url,payload, config).then(respose => {
      return respose
    })
  },
  addpayloadtourl(url) {
    return url
  },
  addHeaders() {
    axiosApi.defaults.headers.common['Authorization'] = AuthToken.getToken()
  },
  addParam() {
    axiosApi.defaults.headers.params  = AuthToken.getToken()
  },
  appendParams(originalOptions, paramsObj) {
    let params = new URLSearchParams();

    for (const key in paramsObj) {
      if (paramsObj.hasOwnProperty(key)) {
        if (key === 'offset') {
          const val = paramsObj[key] / 10;
          params = params.append('offset', val.toString());
        //  params = params.append('page_num', val.toString());
        } else {
        console.log('....else....',  paramsObj[key])

          params.append(key, paramsObj[key]);
        }
      }
    }
    axiosApi.params = params
  }
}
