import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"
import Home from '@/views/Home.vue'
Vue.use(VueRouter)

const routes = [
    {
    path: '/invalid',
    name: 'Home',
    component: Home
    },
    {
        path: '/',
        redirect: to => {
            return '/invalid';
        }
        // component: () => import( '@/views/systemSurvey.vue'),
    },
    {
        path: '/systemSurvey',
        component: () => import ('@/views/systemSurvey.vue')
    },
    {
    path: '/systemSurvey/:user_key/:assign_template_key',
    component: () => import( '@/views/systemSurvey.vue'),
    },
    {
        path: '/:user_key/:assign_template_key',
        component: () => import ('@/views/systemSurvey.vue'),
        // meta: { authRequired: true }
    },
    // },
    {
        path: '/thankyou',
        component: () => import ('@/views/thankyou.vue'),
        // meta: { authRequired: true }
    },
    {
        path: '**',
        redirect: to => {
            return '/invalid';
        }
        // component: () => import ('@/views/NotFound.vue')
    },
]

const router = new VueRouter({mode: 'history', base: process.env.BASE_URL, routes})
const waitForStorageToBeReady = async (to, from, next) => {
    await store.restored
}
// Before each route evaluates...
router.beforeEach((to, from, next) => {
    waitForStorageToBeReady()
    const authRequired = to.matched.some((route) => route.meta.authRequired)
    const isLoggedIn = store.state.authData.loggedInUser && store.state.authData.loggedInUser.is_verified == true
    if ((to.path == '/' || to.path == '/login') && store.state.authData && store.state.authData.loggedInUser != null && store.state.authData.loggedInUser.is_verified == true) {
        // if(store.state.authData.loggedInUser != null && store.state.authData.loggedInUser.user_type_id == 1){
        // next("/admin/dashboard")

        // }else if(store.state.authData.loggedInUser != null && store.state.authData.loggedInUser.user_type_id == 2){
        // next("/home")
        // }
        return next("/check-interview-time")
    } else if (authRequired && ! isLoggedIn) {
        return next("/login")
    } else {
        return next();
    }
    return
});


export default router
