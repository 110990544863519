import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import authData from "./modules/authData";
import questionsVideo from "./modules/questionsVideo";

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  strictMode: true,
  storage: window.localStorage,
})
export default new Vuex.Store({
  state: {
  },
  mutations: {
     RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION // this mutation **MUST** be named "RESTORE_MUTATION"
  },
  actions: {
  },
  modules: {
    authData,
    questionsVideo
  },
  plugins: [vuexLocal.plugin]
})
