import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style.scss'
import '@/mixins/mixin.js'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import VueCarousel from 'vue-carousel' 
import VueCountdownTimer from 'vuejs-countdown-timer'
import VueToast from 'vue-toast-notification'
import VeeValidate from 'vee-validate';
import 'vue-toast-notification/dist/theme-sugar.css'
import "regenerator-runtime/runtime.js";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import browserDetect from "vue-browser-detect-plugin";

Vue.use(VueToast, {
  // One of the options
  position: 'top',
  duration:5000,
  dismissible:true,
  queue:true
});
Vue.use(VueCountdownTimer)
Vue.use(VueCarousel);
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
const config = {
  errorBagName: 'errors', // change if property conflicts
  inject: true, fieldsBagName: 'fields'
};
Vue.use(VeeValidate, config);

Bugsnag.start({
  apiKey: '957c979c1e62d001733255b542a8faaa',
  plugins: [new BugsnagPluginVue()]
})



// Bugsnag.start({
//   apiKey: '957c979c1e62d001733255b542a8faaa',
//   plugins: [new BugsnagPluginVue()]
// })
const bugsnagVue = Bugsnag.getPlugin('vue')


Vue.use(bugsnagVue)
Vue.use(browserDetect);

// bugsnagVue.notify(new Error('Test error'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
