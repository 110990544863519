import apiHandler from "@/services/Api.js"
import API from "@/constants/api-endpoints.js"
import AuthToken from '@/services/AuthToken'
import store from '@/store'
import router from '@/router'
import {questions} from '@/constants/consts'
const defaultStates = {
    questionVideoArr: [],
    currentQuestion: null,
    doneInstructions: false,
    questionDetail: {},
    questionSetWithChild: [],
    interviewQuestionCount: 0,
    allFeedbackFormTemplates: [],
    feedbackFormQuestions: [],
    allAnswersSummery:{},
    appLivestatus:'',
    loading: false,
    templateTheme:{}
}
export default {
    state: defaultStates,
    getters: {
        questionVideoArr: state => state.questionVideoArr,
        currentQuestion: state => state.currentQuestion,
        doneInstructions: state => state.doneInstructions,
        currentQuestionDetail: state => state.questionDetail,
        questionSetWithChild: state => state.questionSetWithChild,
        interviewQuestionCount: state => state.interviewQuestionCount,
        allFeedbackFormTemplates: state => state.allFeedbackFormTemplates,
        appLivestatus:state => state.appLivestatus,
        allAnswersSummery:state => state.allAnswersSummery,
        feedbackFormQuestions:state => state.feedbackFormQuestions,
        templateTheme:state => state.templateTheme
    },
    mutations: {
        setInstruction(state, data) {
            state.doneInstructions = data
            state.loading = false
            state.error = null
        },
        currentQuestion(state, id) {
            state.currentQuestion = id
            state.loading = false
            state.error = null
        },
        setquestionVideoArr(state, data, skipType = 'auto') {
            state.questionVideoArr.push(data)
            state.loading = false
            state.error = null
        },
        setInterviewQuestion(state, {data, index}) {
            if (Array.isArray(data)) {
                state.questionSetWithChild.splice(index, 0, ...data);
            } else {
                const check = state.questionSetWithChild.findIndex(x => x.que_id === data.que_id);
                if (check == '-1') { // state.questionSetWithChild.splice(index, 0, data);
                }
                state.questionSetWithChild.push(data)
            }
            state.loading = false
            state.error = null
        },
        setInterviewQuestionCount(state, data) {
            state.interviewQuestionCount =  data
            // state.interviewQuestionCount = state.interviewQuestionCount + data
            state.loading = false
            state.error = null
        },
        setquestionDetail(state, data) {
            state.questionDetail = data
            state.loading = false
            state.error = null
        },
        setFeedbackFormTemplate(state, data) {
            state.allFeedbackFormTemplates = data
            state.loading = false
            state.error = null
        },
        setFeedbackFormTemplateQuestions(state, data) {
            state.feedbackFormQuestions = data
            state.loading = false
            state.error = null
        },
        setAppStatus(state, data) {
            state.appLivestatus = data
            state.loading = false
            state.error = null
        },
        setAnswersSummery(state, data) {
            state.allAnswersSummery = data
            state.loading = false
            state.error = null
        },
        resetQuestionData(state, data) {
            state.questionVideoArr = [];
            state.currentQuestion = null;
            state.questionDetail = {};
            state.questionSetWithChild = [];
            state.interviewQuestionCount = 0;
            state.allFeedbackFormTemplates = [];
            state.loggedInUser = null;
            state.allFeedbackFormTemplates = [];
        },
        setTemplateTheme(state, data) {
            state.templateTheme = data
        },
        setLoading(state, data) {
            state.loading = data;
            state.error = null;
        },
        setError(state, data) {
            state.error = data;
            state.loading = false;
        },
        clearError(state) {
            state.error = null;
        }
    },
    actions: {
        async addQuestion({commit}, data, index) {
            commit("setInterviewQuestion", index);
        },
        async setVideo({
            commit
        }, data) {
            commit("setLoading", true);
            commit("clearError");
            // const formData = { user_emailaddress: data.user_emailaddress, user_password: data.user_password, user_mpin : data.user_mpin }
            commit("currentQuestion", data.id)
            commit("setquestionVideoArr", data)
            // return data
        },
        async getFeedbackFormTemplate({commit}, data) {
            const id = this.state.authData.loggedInUser.interview_id
            commit("setLoading", true);
            commit("clearError")

            await apiHandler.post(API.FEEDBACK_FORM_API_ENDPOINTS.GET_POST_QUESTIONS, data).then(res => {
                if (res.data.success) {
                    const questionsData = res.data.data
                    this.isLoading = true
                    commit("setFeedbackFormTemplate", questionsData)
                    return questionsData
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                commit("setError", err);
            });
            return data
        },
        async getFeedbackFormTemplateQuestion({commit}, data) {
            commit("setLoading", true);
            commit("clearError")

            await apiHandler.post(API.FEEDBACK_FORM_API_ENDPOINTS.GET_ASIGNED_FORM_QUESTIONS, data).then(res => {
                if (res.data.success) {
                    let questionsData = res.data.data
                    if(questionsData.length > 0 && questionsData[0].sub_task && questionsData[0].sub_task.length > 0 ) {
                        const subTask = questionsData[0].sub_task


                         questionsData[0].sub_task =  subTask.filter(x => {
                            if(x.process_value.length > 0 && x.process_value[0].is_skipped) {
                                // console.log('questionsData.................................')

                            }else {
                                // console.log('is_skipped',x.is_skipped )
                                return x
                            }
                         })
                        commit("setInterviewQuestionCount", questionsData[0].sub_task.length - 1);

                        if(questionsData[0].process_template && questionsData[0].process_template.length > 0 ) {
                            const processTemplate = questionsData[0].process_template[0]
                            const templateTheme = processTemplate.template_theme && processTemplate.template_theme.length > 0 ? processTemplate.template_theme[0] : ''
                            commit("setTemplateTheme", templateTheme )
                        }

                    } else if(questionsData.length > 0  && questionsData[0].is_completed ){
                        commit("setError", { data : {is_completed: true}, message: 'Your have submitted feedback form successfully., This link in expired.'});
                    }

                    this.isLoading = false
                    commit("setFeedbackFormTemplateQuestions", questionsData)
                    return questionsData
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                console.log(err)
                commit("setError", err);
            });
            return data
        },
        async addFeedbackFieldValue({commit}, data) {
            commit("setLoading", true);
            commit("clearError")

            await apiHandler.post(API.FEEDBACK_FORM_API_ENDPOINTS.ADD_FEEDBACK_VALUE, data).then(res => {
                if (res.data.success) {
                    const questionsData = res.data.data
                    this.isLoading = false
                    // commit("setFeedbackFormTemplateQuestions", questionsData)
                    // commit("setInterviewQuestionCount", questionsData.length);
                    return questionsData
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                commit("setError", err);
            });
            return data
        },
        async skipAutoQuestion({
            commit
        }, data) {
            commit("setLoading", true);
            commit("clearError");
            let url

            if( data.skipType == 'auto') {
                url = API.VIDEO_API_ENDPOINTS.SKIP_AUTO_QUESTIONS
            } else if ( data.skipType == 'system_skipped') {
                url = API.VIDEO_API_ENDPOINTS.SKIPPED_QUESTIONS_BY_SYSTEM

            } else {
                url = API.VIDEO_API_ENDPOINTS.SKIP_QUESTIONS
            }
            const formData = {
                interview_id: data.interview_id,
                question_id: data.question_id
            };
            const res = await apiHandler.post(url, formData).then(res => {
                if (res.data.status == true) {
                    this.isLoading = false
                    commit("clearError");
                    return res
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                commit("setError", err);
                return err
            });
            return res
        },
        async submitAnswer({
            commit
            }, data) {
            commit("setLoading", true);
            commit("clearError");
            await apiHandler.post(API.VIDEO_API_ENDPOINTS.SUBMIT_QUESTIONS_VIDEO, data).then(res => {
                if (res.data.status == true) {
                    const userData = res.data.data
                    this.isLoading = false
                    commit("clearError");
                    return userData
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {

                commit("setError", err);
            });
        },
        async setInterviewCompleted({
            commit
        }, data) {
            commit("setLoading", true);
            commit("clearError");
            await apiHandler.post(API.FEEDBACK_FORM_API_ENDPOINTS.CompletedFeedback, data).then(res => {
                if (res.data.status == true) {
                    const userData = res.data.data
                    this.isLoading = false
                    commit("clearError");
                    return userData
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                commit("setError", err);
            });
        },
        // async getInterviewsSummery({
        //     commit
        // }, data) {
        //     commit("setAnswersSummery",null );
        //     commit("setLoading", true);
        //     commit("clearError");
        //     const id = this.state.authData.loggedInUser.interview_id
        //     await apiHandler.post(API.VIDEO_API_ENDPOINTS.getAnswerSummary,{}, {id:id}).then(res => {
        //         if (res.data.status == true) {
        //             const resData = res.data.data
        //             this.isLoading = false
        //             commit("setAnswersSummery",resData );
        //             commit("clearError");
        //             return userData
        //         } else {
        //             commit("setError", res.data);
        //         }
        //     }).catch(err => {
        //         commit("setError", err);
        //     });
        // }
    }
};
