export const environment = {
  production: false
};

// let base_url = ""
// base_url = process.env.VUE_APP_API_URL
let base_url = "http://180.149.245.37:3000/api/manage"
export const  imageBaseUrl = "http://180.149.245.37:3000/"

// let base_url = "http://localhost:3000/api/manage"
// export const  imageBaseUrl = "http://localhost:3000/"
class Endpoints {
  BASE_URL = base_url;
  AUTH_BASE = base_url;
  VENDER_BASE = base_url + "/vendor";
  USER_BASE = base_url + "/user";
  // IMAGE_BASE = 'http://localhost:3005' + '/public'
  IMAGE_BASE = "http://180.149.241.208:3050" + "/public";

  AUTH_ENDPOINTS = {
    // LOGIN: this.joinPaths(this.AUTH_BASE, "send-otp"),
    // verify_otp: this.joinPaths(this.AUTH_BASE, "verify-otp"),
    Get_Clinet_Detail: this.joinPaths(this.AUTH_BASE, "user-master/client-detail")
  };

  FEEDBACK_FORM_API_ENDPOINTS = {
    GET_POST_QUESTIONS: this.joinPaths(this.BASE_URL, "assign-template/get-by-client"),
    GET_ASIGNED_FORM_QUESTIONS: this.joinPaths(this.BASE_URL, "task/get-by-temp-and-assign-feedback-form"),
    ADD_FEEDBACK_VALUE: this.joinPaths(this.BASE_URL, "process-value/add-feedback-value"),
    SKIP_QUESTIONS: this.joinPaths(this.BASE_URL, "skip-question"),
    SKIP_AUTO_QUESTIONS: this.joinPaths(this.BASE_URL, "auto-skip-question"),
    SUBMIT_QUESTIONS_VIDEO: this.joinPaths(
      this.BASE_URL,
      "submit-question-video"
    ),
    ANSWER_VERIFIES: this.joinPaths(this.BASE_URL, "answer-verified"),
    SKIPPED_QUESTIONS_BY_SYSTEM : this.joinPaths(this.BASE_URL, "system-skip-question"),
    CompletedFeedback : this.joinPaths(this.BASE_URL, "assign-template/mark-as-complete-feedback"),
    getAnswerSummary : this.joinPaths(this.BASE_URL, "get-answer-summary")
  };

  joinPaths(...params) {
    const newUrl = params.join("/");
    return newUrl;
  }

  joinPathsWithQueryParams(...params) {
    const newUrl = params.join("?");
    return newUrl;
  }
}

const API = new Endpoints();
export default API;
