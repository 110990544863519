export const serverFileStoragePath = `https://careers.neosofttech.com/~careers/public/images/thumb/`

export const defaultTime = {
    time_to_read:30,
    expected_answer_time:150,
    interview_expiry_hour:2
}
export const msg = {
    INVALID_USER: "Invalid user",
    INVALID_FEEDBACK_URL: "Invalid feedback url",
    INTERNET_CONNECTION_ISSUE: "Please check your Internet connection"
}

